<template>
  <main>
    <div class="card shadow-none">
      <div class="card-header">Motivo de Consulta y Antecedentes</div>
      <div class="card-body">
        <div class="form-row">
          <div class="form-group col-md-12">
            <label class="font-weight-bolder">MOTIVO DE LA CONSULTA</label>
            <textarea
              type="text"
              rows="5"
              v-model="motivo"
              class="form-control"
              :class="{ 'is-invalid': $v.motivo.$error && $v.motivo.$dirty }"
            ></textarea>
          </div>
          <div class="form-group col-md-12">
            <label class="font-weight-bolder">ENFERMEDAD ACTUAL</label>
            <textarea
              type="text"
              rows="5"
              v-model="descripcion"
              class="form-control"
              :class="{
                'is-invalid': $v.descripcion.$error && $v.descripcion.$dirty,
              }"
            ></textarea>
          </div>
        </div>
      </div>
      <div class="card-header">Antecedentes</div>
      <AntecedentComponent
        :idUsuario="idUsuario"
        ref="antecedent"
      ></AntecedentComponent>
    </div>
  </main>
</template>
<script>
import { required } from "vuelidate/lib/validators";

import motivoConsultaService from "../../../../services/motivoConsultaService";
import { isEmpty } from "lodash";
import Toast from "@/components/common/utilities/toast";
import urgenciaTriageService from "../../../../services/urgenciaTriageService";
import AntecedentComponent from "../../antecedente/AntecedentComponent.vue";
import antecedenteUsuarioService from "../../../../services/antecedenteUsuarioService";

export default {
  props: ["idHistoria", "finalizada", "modo", "idUsuario"],
  components: { AntecedentComponent },
  data() {
    return {
      id_historia: "",
      id_motivo_consulta: "",
      motivo: "",
      descripcion: "",
      motivoConsulta: {},
    };
  },
  validations() {
    return {
      motivo: { required },
      descripcion: { required },
    };
  },
  methods: {
    async consultarTriage(id) {
      
      if (this.modo != undefined && this.modo === "URGENCIA") {
        const response = await urgenciaTriageService.showByIdUserAtencion(id);
        if (!isEmpty(response.data)) {
          this.motivo = response.data.motivo_consulta;
        }
      }
    },
    async save() {
      try {
        
        this.$v.$touch();

        if (this.$v.$invalid) {
          return;
        }

        const antecedentes_usuario = this.$refs.antecedent.returnAntecedent();
        
        this.motivoConsulta.id_historia = this.idHistoria;
        this.motivoConsulta.motivo = this.motivo;
        this.motivoConsulta.descripcion = this.descripcion;

        this.LoaderSpinnerShow();

        if (this.id_motivo_consulta !== "") {
          this.motivoConsulta.id = this.id_motivo_consulta;
          await motivoConsultaService.update(this.motivoConsulta);
        } else {
          await motivoConsultaService.store(this.motivoConsulta);
          const response = await motivoConsultaService.showByHc(
            this.idHistoria
          );
          this.id_motivo_consulta = response.data.id;
        }

        await antecedenteUsuarioService.store({
          antecedentes_usuario: antecedentes_usuario,
        });

        this.LoaderSpinnerHide();

        Toast.fire({
          icon: "success",
          title: "Datos guardados con exito",
        });
      } catch (e) {
        console.error(e);
        this.LoaderSpinnerHide();

        Toast.fire({
          icon: "error",
          title: "Ha ocurrido un error al procesar la solicitud",
        });
      }
    },
  },
  watch: {
    finalizada: async function (value) {
      if (value === "N") {
        const response = await motivoConsultaService.showByHc(this.idHistoria);
        if (!isEmpty(response.data)) {
          this.motivo = response.data.motivo;
          this.descripcion = response.data.descripcion;
          this.id_motivo_consulta = response.data.id;
        }
      }
    },
    idUsuario: function (value) {
      if (this.motivo === "") {
        this.consultarTriage(value);
      }
    },
  },
  async created() {
    
    if (this.finalizada === "N") {
      const response = await motivoConsultaService.showByHc(this.idHistoria);
      if (!isEmpty(response.data)) {
        this.motivo = response.data.motivo;
        this.descripcion = response.data.descripcion;
        this.id_motivo_consulta = response.data.id;
      } else {
        this.consultarTriage(this.idUsuario);
      }
    }
  },
};
</script>
