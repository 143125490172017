<template>
    <div class="list-group mb-0 col-lg-12 col-md-12 col-sm-12">
        <div class="list-group-item">
            <div class="d-flex align-items-center justify-content-between">
                <div class="form-group">
                    <label class="form-label">{{ label }}</label>
                    <button @click="selectOption(i)" v-for="(tipo, i) in tipo_opciones" :data-tipo="`tipo_${tipo.id}`"
                        :key="`${i}_tipo`" :class="getButtonClasses(tipo)"
                    >
                        <i :class="tipo.icon"></i>
                        {{ tipo.name }}
                    </button>
                    <div v-show="tipoSeleccionado && tipoSeleccionado.name === 'SI'" class="mt-2">
                        <texto v-if="type === 'TEXT'" v-model="observacion" :class="classObject"></texto>
                        <numerico v-if="type === 'NUM'" v-model="observacion" :class="classObject"></numerico>
                        <date v-if="type === 'DATE'" v-model="observacion" :class="classObject"></date>
                        <opciones :antecedente="antecedente" v-if="type === 'OPC'" v-model="observacion"
                            :class="classObject">
                        </opciones>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import texto from "./tipos/texto";
import numerico from "./tipos/numerico";
import opciones from "./tipos/opciones";
import date from "./tipos/date";
export default {
    components: {
        texto,
        numerico,
        opciones,
        date
    },
    props: ['label', 'value', "validator", "disabled", "type", "antecedente"],
    data() {
        return {
            tipo_opciones: [
                {
                    id: 1,
                    name: 'SI',
                    icon: 'fa fa-check fa-fw',
                    style: 'primary'
                },
                {
                    id: 2,
                    name: 'NO',
                    icon: 'fa fa-times fa-fw',
                    style: 'danger'
                }
            ],
            tipoSeleccionado: '',
            observacion: this.value,
            defaultObservacion: 'NO'
        }
    },
    methods: {
        selectOption(i) {
            const tipo = this.tipo_opciones[i];
            this.tipoSeleccionado = tipo;
            this.observacion = this.tipoSeleccionado.name === 'NO' ? 'NO' : '';
        },
        getButtonClasses(tipo) {
            return {
                'btn': true,
                'btn-outline-primary': tipo !== this.tipoSeleccionado && tipo.name === 'SI',
                'btn-outline-danger': tipo !== this.tipoSeleccionado && tipo.name === 'NO',
                'btn-primary': tipo === this.tipoSeleccionado,
                'btn-danger': tipo === this.tipoSeleccionado && tipo.name === 'NO',
                'btn-xs': true,
                'mx-1': true
            };
        },
    },
    watch: {
        value: async function (value) {

            if (value === "" || value === undefined || value === null) {
                this.observacion = "";
                return;
            }

            this.tipoSeleccionado = value !== 'NO' ? this.tipo_opciones[0] : this.tipo_opciones[1];
            this.observacion = value;
        },
        observacion: function (value) {
            this.$emit('input', value);
        },
    },
    computed: {
        classObject: function () {
            if (this.validator === undefined || this.validator === null) {
                return "";
            }
            return this.validator.$error ? "is-invalid" : "is-valid";
        },
    },
    created() {
        this.tipoSeleccionado = this.value !== 'NO' && this.value !== '' ? this.tipo_opciones[0] : this.tipo_opciones[1];
        this.observacion = this.value === '' ? 'NO' : this.value;
    },
}
</script>