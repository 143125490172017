<template>
    <main>
        <div class="card shadow-none">
            <div class="card-header">Apgar Familiar </div>
            <div class="card-body">
                <div class="table-responsive">
                    <table class="table table-striped table-bordered">
                        <tr>
                            <th>Pregunta</th>
                            <th>Opciones</th>
                        </tr>
                        <tr v-for="pre in preguntas" :key="pre.id">
                            <td>{{ pre.descripcion }}</td>
                            <td>
                                <select class="form-control" v-model="pre.respuesta">
                                    <option v-for="opc in pre.opciones" :key="`${opc.id}_opc`" :value="opc.valor">
                                        {{ opc.opcion }}
                                    </option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td class="font-weight-bolder">
                                <div class="d-flex justify-content-end">
                                    Puntuación: {{ puntaje_total }}
                                </div>
                            </td>
                            <td class="font-weight-bolder text-center" :class="clasificacion_apgar(puntaje_total).class">
                                {{ clasificacion_apgar(puntaje_total).valoracion }}
                            </td>
                        </tr>
                    </table>
                </div>
                <div class="card shadow-none" v-if="false">
                    <div class="card-header">Ultima Valoración: 
                        <span v-if="Object.keys(apgar).length">{{
                                apgar.fecha | formatDate
                                }}</span>
                        <span v-else class="text-danger">Sin datos...</span>
                    </div>
                    <div class="card-body">
                        <table class="table table-striped table-bordered">
                            <tr>
                                <td class="font-weight-bolder">
                                    <div class="d-flex justify-content-end">
                                        Puntuación: 
                                        <span v-if="Object.keys(apgar).length">{{
                                            apgar.puntaje_total
                                            }}
                                        </span>
                                        <span v-else class="text-danger">Sin datos...</span>
                                    </div>
                                </td>
                                <td class="font-weight-bolder text-center" :class="clasificacion_apgar(apgar.puntaje_total).class">
                                    {{ clasificacion_apgar(apgar.puntaje_total).valoracion }}
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div
                    class="alert alert-danger text-center col-lg-12 mt-2"
                    v-if="$v.preguntas.$error && $v.preguntas.$dirty"
                    >
                    Debe completar el formulario
                </div>           
            </div>
        </div>
    </main>
</template>
<script>
import preguntasApgarFamiliarService from "../../../../services/preguntasApgarFamiliarService";
import valoracionApgarFamiliarService from "../../../../services/valoracionApgarFamiliarService";
import Swal from "sweetalert2";
import { isEmpty } from "lodash";
import dayjs from "dayjs";
import { required } from "vuelidate/lib/validators";
import Toast from "../../../../components/common/utilities/toast";
export default {
    props: ["usuario", "finalizada",'type'],
    data() {
        return {
            preguntas: [],
            apgar: {},
        };
    },
    validations(){
        return {
            preguntas:{
                $each:{
                    respuesta:{required}
                }
            }
        }
    },
    watch: {
        usuario: async function () {
            const response = await valoracionApgarFamiliarService.showLast(
                this.usuario.id
            );
            this.apgar = response.data;
        },
        puntaje_total:function(newVal){
            this.clasificacion_apgar(newVal);
        }
    },
    methods: {
        async cargarPreguntas() {

            const respuestas = await valoracionApgarFamiliarService.showLast(
                this.usuario.id
            );

            this.apgar = respuestas.data;

            const response = await preguntasApgarFamiliarService.index(this.type);
            this.preguntas = response.data.map((p) => {

                let respuesta = '';

                if (!isEmpty(respuestas.data.valoracion_detalles)) {
                    const detalle = respuestas.data.valoracion_detalles.find((x) => x.id_pregunta === p.id);
                    if (detalle) {
                        const opcion = p.opciones.find((y) => y.valor === parseInt(detalle.valor));
                        respuesta = opcion ? opcion.valor : '';
                    }
                }

                return {
                    ...p,
                    respuesta:respuesta,
                };
            });
        },
        async cargarRespuestas() {
            const response = await valoracionApgarFamiliarService.showLast(
                this.usuario.id
            );
            this.apgar = response.data;
        },
        clasificacion_apgar(puntaje_total) {
            const clasficicacion_ninos = [
                {
                    inicio: 7,
                    fin: 10,
                    valoracion: "Funcionalidad Normal",
                    class: "table-success",
                },
                {
                    inicio: 4,
                    fin: 6,
                    valoracion: "Disfunción Moderada",
                    class: "table-warning",
                },
                {
                    inicio: 0,
                    fin: 3,
                    valoracion: "Disfunción Grave",
                    class: "table-danger",
                },
            ];

            const clasficicacion_adultos = [
                {
                    inicio: 17,
                    fin: 20,
                    valoracion: "Funcionalidad Normal",
                    class: "table-success",
                },
                {
                    inicio: 13,
                    fin: 16,
                    valoracion: "Disfunción Leve",
                    class: "table-warning",
                },
                {
                    inicio: 10,
                    fin: 12,
                    valoracion: "Disfunción Moderada",
                    class: "table-warning",
                },
                {
                    inicio: 0,
                    fin: 9,
                    valoracion: "Disfunción Grave",
                    class: "table-danger",
                },
            ];

            if (this.edad <= 18) {
                const clasificacion = clasficicacion_ninos.find(
                    (x) =>
                        puntaje_total >= x.inicio &&
                        puntaje_total <= x.fin
                );
                if (clasificacion) return clasificacion;
            }

            if (this.edad > 18) {
                const clasificacion = clasficicacion_adultos.find(
                    (x) =>
                        puntaje_total >= x.inicio &&
                        puntaje_total <= x.fin
                );
                if (clasificacion) return clasificacion;
            }

            return {
                inicio: 0,
                fin: 0,
                valoracion: "Cargando clasificacion",
                class: "span span-default text-danger",
            };
        },
        async save() {
            try {

                this.$v.$touch();
                if (this.$v.$invalid) return;

                let valoracion_apgar = {
                    puntaje_total: this.puntaje_total,
                    id_usuario: this.usuario.id,
                    respuestas: this.preguntas,
                };

                this.LoaderSpinnerShow();

                await valoracionApgarFamiliarService.store(valoracion_apgar);

                this.LoaderSpinnerHide();
                await this.cargarRespuestas();

                Toast.fire({
                    icon: "success",
                    title: "Datos guardados con exito",
                });
            } catch (e) {
                console.error(e);
                this.LoaderSpinnerHide();
                Swal.fire(
                    "Ups!",
                    "ha ocurrido un error al procesar la solicitud",
                    "error"
                );
            }
        },
    },
    async created() {
        await this.cargarPreguntas();
        //await this.cargarRespuestas();
    },
    computed: {
        puntaje_total: function () {
            return this.preguntas.reduce((t, x) => {
                return t + (x.respuesta !== "" ? Number(x.respuesta) : 0);
            }, 0);
        },
        edad: function () {
            if (isEmpty(this.usuario)) return 0;
            return dayjs().diff(this.usuario.fecha_nacimiento, "year");
        },
        
        tiempo_ultimo_apgar() {
            if (isEmpty(this.apgar)) return 0;
            return dayjs().diff(this.apgar.fecha, "year");
        },
    },
};
</script>
