<template>
  <main>
    <table class="table table-striped table-sm table-bordered font-size-12">
      <tr>
        <th>Rango</th>
        <th>Condiciones perinatales y posnatales</th>
        <th>Respuesta</th>
      </tr>
      <tr v-for="item in riesgos" :key="item.id">
        <td>
          {{ item.edad_inicio }} {{ item.tipo_edad_inicio }} a
          {{ item.edad_final }} {{ item.tipo_edad_final }}
        </td>
        <td>{{ item.condiciones_perinatales_posnatales }}</td>
        <td>
          <select v-model="item.respuesta" class="form-control">
            <option value="SI">SI</option>
            <option value="NO">NO</option>
          </select>
        </td>
      </tr>
    </table>
    <div
      class="alert alert-danger text-center"
      v-if="$v.riesgos.$error && $v.riesgos.$dirty"
    >
      Debe completar los riesgos generales
    </div>
    <!--  <div class="col-12 d-flex justify-content-end">
      <button @click="guardar()" class="btn btn-success btn-sm">Guardar</button>
    </div> -->
  </main>
</template>
<script>
import riesgosGeneralesValeService from "../../../../services/riesgosGeneralesValeService";
import historiaRiesgosGeneralesValeService from "../../../../services/historiaRiesgosGeneralesValeService";
import { isEmpty } from "lodash";
import Toast from "../../../../components/common/utilities/toast";
import { required } from "vuelidate/lib/validators";

export default {
  props: ["idHistoria", "finalizada", "idUsuario", "usuario"],
  data() {
    return {
      respuesta: {},
      riesgos: {},
      tieneRespuestas: false,
    };
  },
  validations() {
    return {
      riesgos: {
        $each: {
          respuesta: { required },
        },
      },
    };
  },
  methods: {
    async cargarRiesgos() {
      const response = await riesgosGeneralesValeService.index({
        fecha_nacimiento: this.usuario.fecha_nacimiento,
      });

      const respuestas = await historiaRiesgosGeneralesValeService.showByHc(
        this.idHistoria
      );

      if (!isEmpty(respuestas.data)) this.tieneRespuestas = true;

      this.riesgos = response.data.map((riesgo) => {
        let respuesta = "NO";

        if (!isEmpty(respuestas.data)) {
          const tieneRespuesta = respuestas.data.find(
            (x) => parseInt(x.id_riesgo) === parseInt(riesgo.id)
          );

          if (tieneRespuesta !== undefined) {
            respuesta = tieneRespuesta.respuesta;
          }
        }

        return {
          ...riesgo,
          respuesta: respuesta,
        };
      });
    },
    async save() {
      try {
        this.$v.$touch();
        if (this.$v.$invalid) return;

        let historia_riesgos = {
          id_historia: this.idHistoria,
          respuestas: this.riesgos,
        };

        this.LoaderSpinnerShow();

        if (this.tieneRespuestas) {
          await historiaRiesgosGeneralesValeService.update(historia_riesgos);
        } else {
          await historiaRiesgosGeneralesValeService.store(historia_riesgos);
          this.tieneRespuestas = true;
        }

        this.LoaderSpinnerHide();

        Toast.fire({
          icon: "success",
          title: "Datos guardados con exito",
        });
      } catch (e) {
        console.error(e);
        this.LoaderSpinnerHide();
        Toast.fire({
          icon: "error",
          title: "Ocurrio un error al procesar la solicitud",
        });
      }
    },
  },
  created() {
    this.cargarRiesgos();
  },
};
</script>
<style scoped>
.font-size-12 {
  font-size: 12px;
}
</style>
