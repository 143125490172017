<template>
  <main>
    <div class="mt-2 card shadow-none">
      <div class="card-header">Instrumento Vale</div>
      <div class="card-body">
        <ul class="nav nav-tabs mb-3" id="pills-tab" role="tablist">
          <li class="nav-item" role="presentation">
            <a
              class="nav-link active"
              id="pills-home-tab"
              data-toggle="pill"
              href="#pills-home"
              role="tab"
              aria-controls="pills-home"
              aria-selected="true"
              >Riesgos Generales</a
            >
          </li>
          <li class="nav-item" role="presentation">
            <a
              class="nav-link"
              id="pills-profile-tab"
              data-toggle="pill"
              href="#pills-profile"
              role="tab"
              aria-controls="pills-profile"
              aria-selected="false"
              >Condiciones Estructurales</a
            >
          </li>
          <li class="nav-item" role="presentation" >
            <a
              class="nav-link"
              id="pills-contact-tab"
              data-toggle="pill"
              href="#pills-contact"
              role="tab"
              aria-controls="pills-contact"
              aria-selected="false"
              >Items Valoración</a
            >
          </li>
        </ul>
        <div class="tab-content" id="pills-tabContent">
          <div
            class="tab-pane fade show active"
            id="pills-home"
            role="tabpanel"
            aria-labelledby="pills-home-tab"
          >
            <riesgosGenerales
              ref="riesgosGenerales"
              :idHistoria="idHistoria"
              :finalizada="finalizada"
              :idUsuario="idUsuario"
              :usuario="usuario"
            ></riesgosGenerales>
          </div>
          <div
            class="tab-pane fade"
            id="pills-profile"
            role="tabpanel"
            aria-labelledby="pills-profile-tab"
          >
            <condicionesEstructurales
              ref="condicionesEstructurales"
              :idHistoria="idHistoria"
              :finalizada="finalizada"
              :idUsuario="idUsuario"
            ></condicionesEstructurales>
          </div>
          <div
            class="tab-pane fade"
            id="pills-contact"
            role="tabpanel"
            aria-labelledby="pills-contact-tab"
          >
            <itemsValoracion
              ref="itemsValoracion"
              :usuario="usuario"
              :idHistoria="idHistoria"
              :finalizada="finalizada"
              :idUsuario="idUsuario"
            ></itemsValoracion>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import itemsValoracion from "./itemsValoracion";
import riesgosGenerales from "./riesgosGenerales";
import condicionesEstructurales from "./condicionesEstructurales";
import dayjs from "dayjs";

export default {
  props: ["idHistoria", "finalizada", "idUsuario", "usuario"],
  components: { itemsValoracion, riesgosGenerales, condicionesEstructurales },
  computed: {
    edad: function () {
      const now = dayjs();
      return now.diff(this.usuario.fecha_nacimiento, "years");
    },
  },
  methods:{
    async save(){
      await this.$refs.riesgosGenerales.save();
      await this.$refs.condicionesEstructurales.save();
      await this.$refs.itemsValoracion.save();
    }
  }
};
</script>
