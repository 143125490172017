<template>
  <main>
    <table class="table table-striped table-sm table-bordered font-size-12">
      <tr>
        <th>Rango</th>
        <th>Reporte de padre</th>
        <th>Observacion Directa</th>
        <th>Respuesta</th>
        <th>Observacion Directa</th>
      </tr>
      <tr v-for="item in items" :key="item.id">
        <td>
          {{ item.edad_inicio }} {{ item.tipo_edad_inicio }} a
          {{ item.edad_final }} {{ item.tipo_edad_final }}
        </td>
        <td>
          <b>{{ item.tipo_item }}:</b> {{ item.reporte_padres }}
        </td>
        <td>
          <b>{{ item.tipo_item }}:</b> {{ item.observacion_directa }}
        </td>
        <td class="w-10">
          <select v-model="item.respuesta" class="form-control">
            <option value="SI">SI</option>
            <option value="NO">NO</option>
          </select>
        </td>
        <td class="w-10">
          <select v-model="item.item_observacion_directa" class="form-control">
            <option value="SI">SI</option>
            <option value="NO">NO</option>
          </select>
        </td>
      </tr>
    </table>
    <div
      class="alert alert-info text-center shadow-none"
      v-show="items.length === 0"
    >
      <i class="fa fa-info-circle fa-fw"></i>No se encontraron items de valoración para la edad
    </div>
    <div
      class="alert alert-danger text-center"
      v-if="$v.items.$error && $v.items.$dirty"
    >
      Debe completar los items de valoración
    </div>
    <!-- <div class="col-12 d-flex justify-content-end">
        <button @click="guardar()" class="btn btn-success btn-sm">Guardar</button>
        </div> -->
  </main>
</template>
<script>
import itemsValoracionValeService from "../../../../services/itemsValoracionValeService";
import historiaItemsValoracionValeService from "../../../../services/historiaItemsValoracionValeService";
import { isEmpty } from "lodash";
import Toast from "../../../../components/common/utilities/toast";
import { required } from "vuelidate/lib/validators";
export default {
  props: ["idHistoria", "finalizada", "idUsuario", "usuario"],
  data() {
    return {
      respuesta: {},
      item_observacion_directa: {},
      items: {},
      tieneRespuestas: false,
      tieneObservaciones: false,
    };
  },
  validations() {
    return {
      items: {
        $each: {
          respuesta: { required },
          item_observacion_directa:{required}
        },
      },
    };
  },
  methods: {
    async cargarItems() {
      const response = await itemsValoracionValeService.index({
        fecha_nacimiento: this.usuario.fecha_nacimiento,
      });

      const respuestas = await historiaItemsValoracionValeService.showByHc(
        this.idHistoria
      );

      if (!isEmpty(respuestas.data)) {
        this.tieneRespuestas = true;
        this.tieneObservaciones = true;
      }

      this.items = response.data.map((item) => {
        let respuesta = "SI";
        let item_observacion_directa = "SI";

        if (!isEmpty(respuestas.data)) {
          const tieneRespuesta = respuestas.data.find(
            (x) => parseInt(x.id_items_valoracion) === parseInt(item.id)
          );

          if (tieneRespuesta !== undefined) {
            respuesta = tieneRespuesta.respuesta;
          }

          const tieneObservacion = respuestas.data.find(
            (x) => parseInt(x.id_items_valoracion) === parseInt(item.id)
          );

          if (tieneObservacion !== undefined) {
            item_observacion_directa =
              tieneObservacion.item_observacion_directa;
          }
        }

        return {
          ...item,
          respuesta: respuesta,
          item_observacion_directa: item_observacion_directa,
        };
      });
    },
    async save() {
      try {
        this.$v.$touch();
        if (this.$v.$invalid) return;

        let historia_items = {
          id_historia: this.idHistoria,
          respuestas: this.items,
        };

        this.LoaderSpinnerShow();

        if (this.tieneRespuestas && this.tieneObservaciones) {
          await historiaItemsValoracionValeService.update(historia_items);
        } else {
          await historiaItemsValoracionValeService.store(historia_items);
          this.tieneRespuestas = true;
          this.tieneObservaciones = true;
        }

        this.LoaderSpinnerHide();

        Toast.fire({
          icon: "success",
          title: "Datos guardados con exito",
        });
      } catch (e) {
        console.error(e);
        this.LoaderSpinnerHide();
        Toast.fire({
          icon: "error",
          title: "Ocurrio un error al procesar la solicitud",
        });
      }
    },
  },
  created() {
    this.cargarItems();
  },
};
</script>
<style scoped>
.font-size-12 {
  font-size: 12px;
}

.w-10 {
  width: 10%;
}
</style>
