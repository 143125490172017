<template>
    <div class="shadow-none">
        <div class="card-header border-bottom">
            <ul class="nav nav-tabs card-header-tabs" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                    <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab"
                        aria-controls="home" aria-selected="true">AIEPI</a>
                </li>
                <li class="nav-item" role="presentation">
                    <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab"
                        aria-controls="profile" aria-selected="false">ESCALA ABREVIADA DE DESARROLLO</a>
                </li>
                <!-- <li class="nav-item" role="presentation">
                <a class="nav-link" id="contact-tab" data-toggle="tab" href="#contact" role="tab" aria-controls="contact"
                    aria-selected="false">VALE</a>
            </li> -->
                <li class="nav-item" role="presentation">
                    <a class="nav-link" id="examenes-tab" data-toggle="tab" href="#examenes" role="tab"
                        aria-controls="examenes" aria-selected="false">EXAMENES</a>
                </li>
                <!-- <li class="nav-item" role="presentation">
          <a
            class="nav-link"
            id="apgar-tab"
            data-toggle="tab"
            href="#apgar"
            role="tab"
            aria-controls="apgar"
            aria-selected="false"
            >APGAR</a
          >
        </li> -->
                <!--  <li class="nav-item" role="presentation">
          <a
            class="nav-link"
            id="mchatr-tab"
            data-toggle="tab"
            href="#mchatr"
            role="tab"
            aria-controls="mchatr"
            aria-selected="false"
            >M-CHAT-R</a
          >
        </li> -->
                <!-- <li class="nav-item" role="presentation">
                <a class="nav-link" id="agudeza-tab" data-toggle="tab" href="#agudeza" role="tab" aria-controls="agudeza"
                    aria-selected="false">AGUDEZA VISUAL</a>
            </li> -->
            </ul>
        </div>
        <div class="card-body">
            <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                    <index :idHistoria="idHistoria" :usuario="usuario" :finalizada="finalizada" :idUsuario="idUsuario">
                    </index>
                </div>
                <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                    <indexEvaluacion :usuario="usuario" :idHistoria="idHistoria" :finalizada="finalizada"
                        :idUsuario="idUsuario"></indexEvaluacion>
                </div>
                <!--  <div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                <indexVale :usuario="usuario" :idHistoria="idHistoria" :finalizada="finalizada" :idUsuario="idUsuario">
                </indexVale>
            </div> -->
                <div class="tab-pane fade" id="examenes" role="tabpanel" aria-labelledby="examenes-tab">
                    <indexExamenes :idHistoria="idHistoria" :usuario="usuario" :finalizada="finalizada"
                        :id-tipo-historia="idTipoHistoria"></indexExamenes>
                </div>
                <!-- <div
          class="tab-pane fade"
          id="apgar"
          role="tabpanel"
          aria-labelledby="apgar-tab"
        >
          <apgarFamiliar
            :idHistoria="idHistoria"
            :usuario="usuario"
            :finalizada="finalizada"
            :id-tipo-historia="idTipoHistoria"
          ></apgarFamiliar>
        </div> -->
                <div class="tab-pane fade" id="mchatr" role="tabpanel" aria-labelledby="mchatr-tab">
                    <!-- <mchatr :idHistoria="idHistoria" :finalizada="finalizada"></mchatr> -->
                </div>
                <!--  <div class="tab-pane fade" id="agudeza" role="tabpanel" aria-labelledby="agudeza-tab">
                <agudezaVisual :idHistoria="idHistoria" :finalizada="finalizada" :codigo="'AV'"></agudezaVisual>
            </div> -->
            </div>
        </div>
    </div>
</template>
<script>
import index from "../base/baseAiepi/index/index";
import indexEvaluacion from "../base/baseEscalaAbreviadaDesarrollo/index/indexEvaluacion";
//import indexVale from "../base/baseVale/indexVale";
import indexExamenes from "../base/baseReporteExamenesAdolescente/indexExamenes";
//import apgarFamiliar from "../base/baseApgarFamiliar/apgarHistoria";
//import mchatr from "../base/baseMchatr/indexMchatr";
//import agudezaVisual from "../base/baseAgudezaVisual/index";
export default {
    props: ["idHistoria", "finalizada", "idUsuario", "usuario", "idTipoHistoria"],
    components: { index, indexEvaluacion, indexExamenes },
};
</script>
